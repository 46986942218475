@mixin bp($min, $max: $min)
{
  $MediaQueriesEnabled: true !default;

  @if $MediaQueriesEnabled
  {
    $bps: xs, sm, md, lg;
    $valid-input: true;
    $use-media-query: true;
    $mq: "";

    $maxes: (
      xs: "(max-width: #{$screen-xs-max})",
      sm: "(max-width: #{$screen-sm-max})",
      md: "(max-width: #{$screen-md-max})"
    );

    $mins: (
      sm: "(min-width: #{$screen-sm-min})",
      md: "(min-width: #{$screen-md-min})",
      lg: "(min-width: #{$screen-lg-min})"
    );

    @if($min != null and $max != null)
    {
      @if index($bps, $min) and index($bps, $max)
      {
        $mq: "#{map-get($mins, $min)} and #{map-get($maxes, $max)}"
      }
      @else
      {
        @warn "Breakpoint parameters must be either xs, sm, md, or lg.";
        $valid-input: false;
      }
    }

    @else if ($min != null and $max == null)
    {
      @if $min == xs
      {
        @warn "There is no min-width for Mobile Screens.";
        $valid-input: false;
      }
      @else if $min == min
      {
        $use-media-query: false;
      }
      @else if $min == max
      {
        $valid-input: false;
      }
      @else
      {
        $mq: "#{map-get($mins, $min)}"
      }
    }

    @else if ($min == null and $max != null)
    {
      @if $max == lg
      {
        @warn "There is no Max Width for Large Screens.";
        $valid-input: false;
      }
      @else if $max == max
      {
        $use-media-query: false;
      }
      @else if $max == min
      {
        $valid-input: false;
      }
      @else
      {
        $mq: "#{map-get($maxes, $max)}"
      }
    }
    @else
    {
      @warn "You must pass a parameter to the bp() mixin!";
      $valid-input: false;
    }
    @if $valid-input and $use-media-query
    {
      @media #{$mq} { @content;}
    }
    @else if $valid-input
    {
      @content;
    }
  }
}

@mixin bplte($max)
{
  @include bp(null, $max)
  {
    @content;
  }
}

@mixin bpgte($min)
{
  @include bp($min, null)
  {
    @content;
  }
}

@mixin hoverActive
{
  @include bplte(xs)
  {
    &:active
    {
      @content;
    }
  }

  @include bpgte(sm)
  {
    &:hover
    {
      @content;
    }
  }
}

@mixin anti-aliased
{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin font-sizes($font-size, $line-height: 'normal', $font-size-xs: $font-size, $line-height-xs: $line-height, $bp: sm)
{
  font-size: $font-size-xs;
  line-height: $line-height-xs;

  @if $font-size != $font-size-xs or $line-height != $line-height-xs
  {
    @include bpgte($bp)
    {
      font-size: $font-size;
      line-height: $line-height;
    }
  }
}
