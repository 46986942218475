.Factor
{
  color: $blue-light;

  &:not(:last-child)
  {
    padding-bottom: $gutter-mini*4;
  }

  &[hasvalue=false],
  &[ischanging=true]
  {
    color: $white;

    .Factor-input
    {
      border-color: $white;
    }
  }

  &[hasvalue=true],
  &[ischanging=true],
  &[hasvalue=false] &-label:hover
  {
    .Factor-prompt
    {
      @include font-sizes(16px, 24px);
      bottom: $gutter*2;
      padding-top: $gutter/2;
    }
  }

  &-label
  {
    display: block;
    position: relative;
    padding-top: $gutter-mini*2;
  }

  &-prompt
  {
    @include font-sizes(24px, 32px);
    position: absolute;
    text-align: center;
    bottom: 0;
    width: 100%;
    transition: all $transition-fast;
  }

  &-input
  {
    @include font-sizes(24px, 32px);
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    appearance: none;
    text-align: center;
    background-color: transparent;
    outline: 0;
    border-bottom: $border-default $blue-light;
    transition: all $transition-fast;

    @include bplte(xs)
    {
      width: 80%;
    }

    &--select
    {
      @include Select;
    }

    &:focus
    {
      width: 75%;

      @include bplte(xs)
      {
        width: 100%;
      }
    }

    &[type=number]
    {
      -moz-appearance: textfield;
    }

    &::-webkit-inner-spin-button
    {
      appearance: none;
      margin: 0;
    }
  }
}
