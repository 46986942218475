.Results
{
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 60%;

  &-bar
  {
    position: relative;
    width: $gutter*6;
    transition: all $transition-slow;

    @include bplte(xs)
    {
      width: $gutter*4;
    }
  }

  &-num
  {
    @include font-sizes(32px, 48px);
    font-weight: bold;
    text-align: center;
    position: absolute;
    bottom: 100%;
    width: 100%;
    z-index: 1;
  }

  &-label
  {
    @include font-sizes(12px, 16px);
    font-weight: bold;
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    transform: translateY(100%);
    z-index: 1;
  }

  &-virus
  {
    position: absolute;
    transition: all linear;
    transform: translateX(-50%);
  }
}
