.BaseModal
{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $black-transparent;
  z-index: 1;

  &-heading
  {
    @include font-sizes(24px, 32px);
    text-align: center;
  }

  &-factors
  {
    margin-top: $gutter-mini*2;
  }

  &-content
  {
    position: absolute;
    width: 50%;
    left: 25%;
    top: 50%;
    transform: translateY(-50%);
    background-color: $black-transparent;
    max-height: calc(100% - #{$gutter*2});
    overflow-y: auto;
    padding: $gutter-mini*2;
    background-color: $blue-dark;

    @include bp(sm)
    {
      width: 75%;
      left: 12.5%;
    }

    @include bplte(xs)
    {
      width: calc(100% - #{$gutter*2});
      left: $gutter;
    }
  }

  &-closeIcon
  {
    position: absolute;
    top: $gutter;
    right: $gutter;
    font-size: $gutter-mini*2;
  }

  &-close
  {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
}
