.Main
{
  position: relative;
  color: $white;
  height: 100vh;
  width: 100vw;
  overflow: auto;

  &-header
  {
    position: absolute;
    height: $gutter*3;
    width: calc(100% - #{$gutter*2});
    top: $gutter;
    left: $gutter;
    color: $blue-light;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-titleLogo
  {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &-logo
  {
    height: 100%;
  }

  &-title
  {
    padding-left: $gutter/2;
    padding-top: $gutter-mini;
  }

  &-baseValuesButton
  {
    height: 100%;
    display: flex;
    align-items: center;
    padding: $gutter/2 $gutter-mini;
    background-color: $white;
    color: $blue-dark;
    transition: background-color $transition-fast;

    @include bplte(xs)
    {
      padding: $gutter-mini/2 $gutter;
    }

    @include hoverActive
    {
      background-color: $blue-light;
    }
  }

  &-baseValuesText
  {
    padding-right: $gutter/2;

    @include bplte(xs)
    {
      display: none;
    }
  }

  &-container
  {
    display: flex;
    height: 100%;
  }

  &-left
  {
    flex: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include bplte(xs)
    {
      flex: 3;
      max-width: 75%;
    }
  }

  &-right
  {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-footer
  {
    @include font-sizes(14px, 18px);
    position: absolute;
    width: 100%;
    bottom: 0;
    color: $blue-light;
    padding-top: $gutter/4;
    padding-bottom: $gutter/4;
    text-align: center;

    @include bplte(xs)
    {
      @include font-sizes(12px, 16px);
    }
  }
}
