$font-main: 'Lato', sans-serif;

$gutter: 16px;
$gutter-mini: 12px;

$screen-xs-min: 480px;
$screen-xs-max: 767px;
$screen-sm-min: 768px;
$screen-sm-max: 991px;
$screen-md-min: 992px;
$screen-md-max: 1199px;
$screen-lg-min: 1200px;

$container-tablet-base: 768px;
$container-desktop-base: 992px;
$container-large-desktop-base: 1170px;

$black: #000;
$white: #fff;
$black-transparent: rgba(0, 0, 0, 0.55);

$blue-dark: #142850;
$blue-medium: #27496d;
$blue-light: #9bcefa;
$teal: #00909e;

$border-default: 2px solid;
$border-thin: 1px solid;
$border-thin-transparent: 1px solid transparent;

$transition-slow: 400ms ease-in-out;
$transition-fast: 200ms ease-in-out;
$transition-xfast: 100ms ease-in-out;

$screen-xs-min: 480px;
$screen-xs-max: 767px;
$screen-sm-min: 768px;
$screen-sm-max: 991px;
$screen-md-min: 992px;
$screen-md-max: 1199px;
$screen-lg-min: 1200px;
